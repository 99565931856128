import cx from 'classnames'
import { useMemo } from 'react'

import { type SanityGrid } from '@data/sanity/queries/types/modules'
import { borderRadiusClassMap } from '@lib/border'
import { backgroundColorClasses } from '@lib/color'
import {
  getDefaultVerticalSpacingClasses,
  getSpacingClasses,
  getSpacingValuesFromGridColumnSizes,
} from '@lib/spacing'

import GridBlock from './grid-block'

type GridProps = SanityGrid

/**
 * Gets CSS class that sets grid size.
 */
const getGridSizeClass = (
  breakpoint?: string,
  size?: number,
  justify: string | boolean = false,
  align: string | boolean = false,
  start: number | boolean = false
) => {
  const hasBreakpoint = breakpoint && breakpoint.trim()
  const colSpan = hasBreakpoint
    ? `${breakpoint}:col-span-${size}`
    : `col-span-${size}`
  const colStart = hasBreakpoint
    ? `${breakpoint}:col-start-${start}`
    : `col-start-${start}`
  const colJustify = hasBreakpoint ? `${breakpoint}:${justify}` : justify
  const colAlign = hasBreakpoint ? `${breakpoint}:${align}` : align

  return cx(
    colSpan,
    start && colStart,
    justify && colJustify,
    align && colAlign
  )
}

/**
 * Gets CSS class that sets grid item order.
 */
const getItemOrderClass = (
  index: number,
  breakpoint?: string,
  size?: number,
  totalSize?: number,
  reverseWhenFullWidth?: boolean
) => {
  const hasBreakpoint = breakpoint && breakpoint.trim()
  const orderIndex =
    reverseWhenFullWidth && size === totalSize ? 12 - index : index + 1
  const order = hasBreakpoint
    ? `${breakpoint}:order-${Math.abs(orderIndex)}`
    : `order-${Math.abs(orderIndex)}`

  return cx(`${orderIndex < 0 ? '-' : ''}${order}`)
}

const Grid = ({
  size,
  spacing,
  columns: rawColumns,
  reverseSequence,
  background,
  noColumnGaps,
  noRowGaps,
}: GridProps) => {
  const columns = useMemo(
    () =>
      rawColumns?.map((column, columnIndex) => {
        if (!column.sizes) {
          return {
            ...column,
            classes: [],
          }
        }

        const columnClasses = column.sizes.map(
          ({ breakpoint, width, justify, align, start }) =>
            getGridSizeClass(breakpoint, width, justify, align, start)
        )
        const orderClasses = column.sizes.map(({ breakpoint, width }) =>
          getItemOrderClass(
            columnIndex,
            breakpoint,
            width,
            size,
            reverseSequence
          )
        )
        const spacingValues = getSpacingValuesFromGridColumnSizes(column.sizes)

        return {
          ...column,
          classes: [
            ...columnClasses,
            ...orderClasses,
            column.background
              ? backgroundColorClasses[column.background]
              : null,
            column.borderRadius
              ? borderRadiusClassMap[column.borderRadius]
              : null,
            getSpacingClasses(spacingValues),
          ],
        }
      }),
    [rawColumns, reverseSequence, size]
  )

  const sectionClasses = useMemo(
    () => [
      background ? backgroundColorClasses[background] : null,
      ...getDefaultVerticalSpacingClasses(spacing?.padding),
      getSpacingClasses(spacing?.padding, 'right'),
      getSpacingClasses(spacing?.padding, 'left'),
    ],
    [background, spacing]
  )

  return (
    <section className={cx(sectionClasses)}>
      <div className="relative container">
        <div className="mx-auto">
          <div
            className={cx(
              'grid gap-x-4 gap-y-8 sm:gap-x-8 lg:gap-x-12 lg:gap-y-12',
              `grid-cols-${size}`,
              {
                '!gap-x-0': noColumnGaps,
                '!gap-y-0': noRowGaps,
              }
            )}
          >
            {columns?.map(({ _key, blocks, classes }) => (
              <div key={_key} className={cx(classes)}>
                {blocks?.map((block) => (
                  <GridBlock key={block._key} block={block} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
